var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c("b-col", { staticClass: "pr-0" }, [
            _c("button", { on: { click: _vm.rechargeData } }, [
              _vm._v("Recargar")
            ])
          ]),
          _c("b-col", { staticClass: "pr-0" }, [
            _vm._v("Percentage #1: "),
            _vm.percentOne >= 80
              ? _c("span", { staticClass: "text-success" }, [
                  _vm._v(_vm._s(_vm.percentOne.toFixed(2)) + "% ")
                ])
              : _c("span", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.percentOne.toFixed(2)) + "% ")
                ])
          ]),
          _c("b-col", { staticClass: "pr-0" }, [
            _vm._v("Percentage #2: "),
            _vm.percentTwo >= 80
              ? _c("span", { staticClass: "text-success" }, [
                  _vm._v(_vm._s(_vm.percentTwo.toFixed(2)) + "% ")
                ])
              : _c("span", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.percentTwo.toFixed(2)) + "% ")
                ])
          ]),
          _c("b-col", { staticClass: "pr-0" }, [
            _vm._v("Percentage #3: "),
            _vm.percentThree >= 80
              ? _c("span", { staticClass: "text-success" }, [
                  _vm._v(_vm._s(_vm.percentThree.toFixed(2)) + "% ")
                ])
              : _c("span", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.percentThree.toFixed(2)) + "% ")
                ])
          ]),
          _c("b-col", { staticClass: "pr-0" }, [
            _vm._v("Percentage #4: "),
            _vm.percentFour >= 80
              ? _c("span", { staticClass: "text-success" }, [
                  _vm._v(_vm._s(_vm.percentFour.toFixed(2)) + "% ")
                ])
              : _c("span", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.percentFour.toFixed(2)) + "% ")
                ])
          ]),
          _c("b-col", { staticClass: "pr-0" }, [
            _vm._v("Media #1-Time: "),
            _c("span", { staticClass: "text-info" }, [
              _vm._v(_vm._s(_vm.mediaTimeOne.toFixed(2)) + "s")
            ])
          ]),
          _c("b-col", { staticClass: "pr-0" }, [
            _vm._v("Media #3-Time: "),
            _c("span", { staticClass: "text-info" }, [
              _vm._v(_vm._s(_vm.mediaTimeTwo.toFixed(2)) + "s")
            ])
          ])
        ],
        1
      ),
      _c("b-table", {
        staticClass: "ana-table",
        attrs: {
          responsive: "sm",
          "primary-key": "clickNum",
          "tbody-tr-class": _vm.rowClass,
          "tbody-transition-props": _vm.transProps,
          selectable: "",
          "select-mode": "single",
          fields: _vm.fieldsAnalytics,
          items: _vm.items,
          filter: _vm.filter,
          filterIncludedFields: _vm.filterOn,
          "sort-by": _vm.sortBy,
          "sort-desc": _vm.sortDesc,
          "sort-direction": _vm.sortDirection,
          small: "",
          bordered: "",
          hover: ""
        },
        on: {
          refreshed: _vm.mediaTime,
          "update:sortBy": function($event) {
            _vm.sortBy = $event
          },
          "update:sort-by": function($event) {
            _vm.sortBy = $event
          },
          "update:sortDesc": function($event) {
            _vm.sortDesc = $event
          },
          "update:sort-desc": function($event) {
            _vm.sortDesc = $event
          },
          filtered: _vm.onFiltered
        },
        scopedSlots: _vm._u([
          {
            key: "cell(clickNum)",
            fn: function(data) {
              return [
                _c("div", { on: { click: data.toggleDetails } }, [
                  _vm._v(
                    _vm._s(data.value.account) +
                      " // " +
                      _vm._s(data.value.campaign)
                  )
                ]),
                _c("div", { on: { click: data.toggleDetails } }, [
                  _vm._v(
                    _vm._s(
                      _vm
                        ._f("moment")(data.value.date)
                        .format("DD-MM-YYYY HH:mm:ss")
                    )
                  )
                ])
              ]
            }
          },
          {
            key: "cell(proxyServer)",
            fn: function(data) {
              return [
                _c("div", { staticClass: "table-ul-list tb-list" }, [
                  _c("div", { staticClass: "inline-list" }, [
                    _c(
                      "div",
                      [
                        data.value.proxyserver
                          ? _c("feather-icon", {
                              staticClass: "text-success ml-0",
                              attrs: { icon: "CheckIcon" }
                            })
                          : _c("feather-icon", {
                              staticClass: "text-danger ml-0",
                              attrs: { icon: "XIcon" }
                            })
                      ],
                      1
                    ),
                    data.value.date
                      ? _c("div", [
                          _vm._v(
                            "\n            (" +
                              _vm._s(data.value.pos) +
                              ")\n          "
                          )
                        ])
                      : _vm._e()
                  ]),
                  data.value.time != 0
                    ? _c("div", [_vm._v("(" + _vm._s(data.value.time) + " s)")])
                    : _vm._e()
                ])
              ]
            }
          },
          {
            key: "cell(proxyData)",
            fn: function(data) {
              return [
                _c("ul", { staticClass: "table-ul-list" }, [
                  _c(
                    "li",
                    [
                      data.value.proxydata
                        ? _c("feather-icon", {
                            staticClass: "text-success ml-0",
                            attrs: { icon: "CheckIcon" }
                          })
                        : _c("feather-icon", {
                            staticClass: "text-danger ml-0",
                            attrs: { icon: "XIcon" }
                          })
                    ],
                    1
                  ),
                  data.value.date
                    ? _c("li", [
                        _vm._v(
                          "\n          (" +
                            _vm._s(data.value.pos) +
                            ")\n        "
                        )
                      ])
                    : _vm._e()
                ])
              ]
            }
          },
          {
            key: "cell(clientProxy)",
            fn: function(data) {
              return [
                _c("div", { staticClass: "table-ul-list tb-list" }, [
                  _c("div", { staticClass: "inline-list" }, [
                    _c(
                      "div",
                      [
                        data.value.clientproxy
                          ? _c("feather-icon", {
                              staticClass: "text-success ml-0",
                              attrs: { icon: "CheckIcon" }
                            })
                          : _c("feather-icon", {
                              staticClass: "text-danger ml-0",
                              attrs: { icon: "XIcon" }
                            })
                      ],
                      1
                    ),
                    data.value.date
                      ? _c("div", [
                          _vm._v(
                            "\n            (" +
                              _vm._s(data.value.pos) +
                              ")\n          "
                          )
                        ])
                      : _vm._e()
                  ]),
                  data.value.time != 0
                    ? _c("div", [_vm._v(_vm._s(data.value.time) + " s")])
                    : _vm._e()
                ])
              ]
            }
          },
          {
            key: "cell(clientData)",
            fn: function(data) {
              return [
                _c("ul", { staticClass: "table-ul-list" }, [
                  _c(
                    "li",
                    [
                      data.value.clientdata
                        ? _c("feather-icon", {
                            staticClass: "text-success ml-0",
                            attrs: { icon: "CheckIcon" }
                          })
                        : _c("feather-icon", {
                            staticClass: "text-danger ml-0",
                            attrs: { icon: "XIcon" }
                          })
                    ],
                    1
                  ),
                  data.value.date
                    ? _c("li", [
                        _vm._v(
                          "\n          (" +
                            _vm._s(data.value.pos) +
                            ")\n        "
                        )
                      ])
                    : _vm._e()
                ])
              ]
            }
          },
          {
            key: "row-details",
            fn: function(data) {
              return [
                _c("b-card", [
                  _c(
                    "ul",
                    { staticClass: "showcontainer" },
                    _vm._l(data.item, function(value, key) {
                      return _c("li", { key: key }, [
                        key === "gCampaign" ||
                        key === "clickNum" ||
                        key === "proxyServerTime" ||
                        key === "proxyDataTime" ||
                        key === "clientProxyTime" ||
                        key === "clientDataTime"
                          ? _c("div", { staticClass: "innercontainer" }, [
                              _c("div", [_vm._v(_vm._s(key) + ": ")]),
                              key === "clickNum" || key === "gCampaign"
                                ? _c("div", [
                                    _c("span", { staticClass: "text-small" }, [
                                      _vm._v(_vm._s(value))
                                    ])
                                  ])
                                : value
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm
                                          ._f("moment")(value)
                                          .format("DD-MM-YYYY HH:mm:ss")
                                      )
                                    )
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ])
                    }),
                    0
                  )
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }