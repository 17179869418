<template>
  <div>
    <b-row>
      <b-col class="pr-0"><button @click="rechargeData">Recargar</button></b-col>
      <b-col class="pr-0">Percentage #1: <span v-if="percentOne>=80" class='text-success'>{{ percentOne.toFixed(2) }}% </span><span v-else class='text-danger'>{{ percentOne.toFixed(2) }}% </span></b-col>
      <b-col class="pr-0">Percentage #2: <span v-if="percentTwo>=80" class='text-success'>{{ percentTwo.toFixed(2) }}% </span><span v-else class='text-danger'>{{ percentTwo.toFixed(2) }}% </span></b-col>
      <b-col class="pr-0">Percentage #3: <span v-if="percentThree>=80" class='text-success'>{{ percentThree.toFixed(2) }}% </span><span v-else class='text-danger'>{{ percentThree.toFixed(2) }}% </span></b-col>
      <b-col class="pr-0">Percentage #4: <span v-if="percentFour>=80" class='text-success'>{{ percentFour.toFixed(2) }}% </span><span v-else class='text-danger'>{{ percentFour.toFixed(2) }}% </span></b-col>
      <b-col class="pr-0">Media #1-Time: <span class="text-info">{{mediaTimeOne.toFixed(2)}}s</span></b-col>
      <b-col class="pr-0">Media #3-Time: <span class="text-info">{{mediaTimeTwo.toFixed(2)}}s</span></b-col>
    </b-row>
    <b-table responsive="sm" class="ana-table" primary-key="clickNum" @refreshed="mediaTime" :tbody-tr-class="rowClass" :tbody-transition-props="transProps" selectable select-mode="single" :fields="fieldsAnalytics" :items="items" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered" small bordered hover>
      <template v-slot:cell(clickNum)="data">
        <div @click="data.toggleDetails">{{data.value.account}} // {{data.value.campaign}}</div>
        <div @click="data.toggleDetails">{{data.value.date | moment().format("DD-MM-YYYY HH:mm:ss")}}</div>
      </template>
      <template v-slot:cell(proxyServer)="data">
        <div class="table-ul-list tb-list">
          <div class="inline-list">
            <div>
              <feather-icon v-if="data.value.proxyserver" icon="CheckIcon" class="text-success ml-0"></feather-icon>
              <feather-icon v-else icon="XIcon" class="text-danger ml-0"></feather-icon>
            </div>
            <div v-if="data.value.date">
              ({{data.value.pos}})
            </div>
          </div>
          <div v-if="data.value.time!=0">({{data.value.time}} s)</div>
        </div>
      </template>
      <template v-slot:cell(proxyData)="data">
        <ul class="table-ul-list">
          <li>
            <feather-icon v-if="data.value.proxydata" icon="CheckIcon" class="text-success ml-0"></feather-icon>
            <feather-icon v-else icon="XIcon" class="text-danger ml-0"></feather-icon>
          </li>
          <li v-if="data.value.date">
            ({{data.value.pos}})
          </li>
        </ul>
      </template>
      <template v-slot:cell(clientProxy)="data">
        <div class="table-ul-list tb-list">
          <div class="inline-list">
            <div>
              <feather-icon v-if="data.value.clientproxy" icon="CheckIcon" class="text-success ml-0"></feather-icon>
              <feather-icon v-else icon="XIcon" class="text-danger ml-0"></feather-icon>
            </div>
            <div v-if="data.value.date">
              ({{data.value.pos}})
            </div>
          </div>
          <div v-if="data.value.time!=0">{{data.value.time}} s</div>
        </div>
      </template>
      <template v-slot:cell(clientData)="data">
        <ul class="table-ul-list">
          <li>
            <feather-icon v-if="data.value.clientdata" icon="CheckIcon" class="text-success ml-0"></feather-icon>
            <feather-icon v-else icon="XIcon" class="text-danger ml-0"></feather-icon>
          </li>
          <li v-if="data.value.date">
            ({{data.value.pos}})
          </li>
        </ul>
      </template>
      <template v-slot:row-details="data">
        <b-card>
          <ul class="showcontainer">
            <li v-for="(value, key) in data.item" :key="key">
              <div class="innercontainer" v-if="key === 'gCampaign' || key==='clickNum' || key==='proxyServerTime' || key==='proxyDataTime' || key==='clientProxyTime' || key==='clientDataTime'">
                <div>{{ key }}: </div>
                <div v-if="key==='clickNum' || key === 'gCampaign'"><span class='text-small'>{{ value }}</span></div>
                <div v-else-if="value">{{ value | moment().format("DD-MM-YYYY HH:mm:ss")}}</div>
              </div>
            </li>
          </ul>
        </b-card>
      </template>
    </b-table>
  </div>
</template>

<script>
import Amplify from 'aws-amplify'

export default {
  data() {
    return {
      timeProxy: 0,
      timeClient: 0,
      mediaTimeOne: 0,
      mediaTimeTwo: 0,
      percentOne: 0,
      percentTwo: 0,
      percentThree: 0,
      percentFour: 0,
      transProps: {
        name: 'flip-list'
      },
      firstElement: null,
      perPage: 101,
      sortBy: '',
      rowSel: false,
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      ipSelectec: '',
      filterOn: [],
      items: [],
      fieldsAnalytics: [
        {
          key: 'clickNum',
          label: 'Click',
          formatter: (value, key, item) => {
            return { clickId: value, account: item.accountName, campaign: item.campaignName, date: item.firstDate }
          },
          class: 'table-click-column',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'proxyServer',
          label: '#1',
          formatter: (value, key, item) => {
            return { proxyserver: value, click: item.clickNum, date: item.proxyServerTime, pos: item.proxyOrder, time: item.timediffProxy }
          },
          class: 'table-ps-column',
          sortable: true,
          sortDirection: 'desc',
        },
        {
          key: 'proxyData',
          label: '#2',
          formatter: (value, key, item) => {
            return { proxydata: value, click: item.clickNum, date: item.proxyDataTime, pos: item.proxyDataOrder }
          },
          class: 'table-pd-column',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'clientProxy',
          label: '#3',
          formatter: (value, key, item) => {
            return { clientproxy: value, click: item.clickNum, date: item.clientProxyTime, pos: item.clientOrder, time: item.timediffClient }
          },
          class: 'table-cp-column',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'clientData',
          label: '#4',
          formatter: (value, key, item) => {
            return { clientdata: value, click: item.clickNum, date: item.clientDataTime, pos: item.clientDataOrder }
          },
          class: 'table-cd-column',
          sortable: true,
          sortDirection: 'desc'
        },
      ],
    }
  },
  methods: {
    mediaTime() {
      this.items.forEach(item => {
        let calculate = {
          numProxy: 0,
          percentProxy: 0,
          finalProxy: 0,
          numClient: 0,
          percentClient: 0,
          finalClient: 0
        }
        if (item.timediffProxy !== 0) {
          calculate.numProxy += 1
          calculate.percentProxy += item.timediffProxy
        }
        if (item.timediffClient !== 0) {
          calculate.numClient += 1
          calculate.percentClient += item.timediffClient
        }
        calculate.finalProxy = calculate.percentProxy / calculate.numProxy
        calculate.finalClient = calculate.percentClient / calculate.numClient
        if (isNaN(calculate.finalProxy)) {
          this.mediaTimeOne = 0.00
        } else {
          this.mediaTimeOne = calculate.finalProxy
        }
        if (isNaN(calculate.finalClient)) {
          this.mediaTimeTwo = 0.00
        } else {
          this.mediaTimeTwo = calculate.finalClient
        }
      })
    },
    calculatePercent() {
      let firstPercent = 0
      let secondPercent = 0
      let thirdPercent = 0
      let fourthPercent = 0
      this.items.forEach(item => {
        if (item.proxyServer) {
          firstPercent += 1
        }
        if (item.proxyData) {
          secondPercent += 1
        }
        if (item.clientProxy) {
          thirdPercent += 1
        }
        if (item.clientData) {
          fourthPercent += 1
        }
        this.percentOne = (firstPercent / this.items.length) * 100
        this.percentTwo = (secondPercent / this.items.length) * 100
        this.percentThree = (thirdPercent / this.items.length) * 100
        this.percentFour = (fourthPercent / this.items.length) * 100
      })
    },
    updateClick(newclick) {
      this.items.forEach(item => {
        if (item.clickNum == newclick.gClid) {
          item._rowVariant = "primary"
          item.proxyServer = newclick["sta.ser.pro"]
          item.proxyData = newclick["sta.ser.dat"]
          item.clientProxy = newclick["sta.cli.pro"]
          item.clientData = newclick["sta.cli.dat"]
          item.proxyServerTime = newclick["sta.ser.pro.tim"]
          item.proxyDataTime = newclick["sta.ser.dat.tim"]
          item.clientProxyTime = newclick["sta.cli.pro.tim"]
          item.clientDataTime = newclick["sta.cli.dat.tim"]
          setTimeout(() => {
            item._rowVariant = "null"
            this.changePos()
            this.calculatePercent()
          }, 500)
        }
      })
    },
    timeDifference(item) {
      this.items.forEach(data => {
        if (data.clickNum === item.clickNum) {
          if (item.proxyServerTime > item.clientProxyTime) {
            data.timediffProxy = (item.proxyServerTime - item.clientProxyTime) / 1000
          } else {
            data.timediffClient = (item.clientProxyTime - item.proxyServerTime) / 1000
          }
        }
      })
    },
    rechargeData() {
      this.items = []
      this.chargeItems()
    },
    showheader() {
      let header = document.querySelectorAll('.vx-navbar-wrapper')
      header[0].classList.remove("vanish")
      let content = document.querySelectorAll('.router-content')
      content[0].classList.remove("no-top")
      let fortd = document.querySelectorAll('.ana-table')
      fortd[0].classList.remove("notdpadding")
      let th = document.querySelectorAll('th')
      for (let j = 0; th[j]; j++) {
        th[j].classList.remove('thsize')
      }
    },
    hideheader() {
      let header = document.querySelectorAll('.vx-navbar-wrapper')
      header[0].classList.add("vanish")
      let content = document.querySelectorAll('.router-content')
      content[0].classList.add("no-top")
      let fortd = document.querySelectorAll('.ana-table')
      fortd[0].classList.add("notdpadding")
      let th = document.querySelectorAll('th')
      for (let j = 0; th[j]; j++) {
        th[j].classList.add('thsize')
      }
    },
    getFirstDate() {
      this.items.forEach(item => {
        if (item.proxyOrder === 1) {
          item.firstDate = item.proxyServerTime
        } else if (item.proxyDataOrder === 1) {
          item.firstDate = item.proxyDataTime
        } else if (item.clientOrder === 1) {
          item.firstDate = item.clientProxyTime
        } else if (item.clientDataOrder === 1) {
          item.firstDate = item.clientDataTime
        }
      })
    },
    compare(a, b) {
      let comparison = 0;
      if (a.time > b.time) {
        comparison = 1
      } else if (a.time < b.time) {
        comparison = -1
      } else if (a.time === undefined && b.time !== undefined) {
        comparison = 1
      } else if (a.time !== undefined && b.time === undefined) {
        comparison = -1
      } else if (a.time === undefined && b.time === undefined) {
        comparison = 1
      }
      return comparison;
    },
    changePos() {
      this.items.forEach(item => {
        let temp = [
          { time: item.proxyServerTime, proxyOrder: item.proxyOrder },
          { time: item.proxyDataTime, proxyDataOrder: item.proxyDataOrder },
          { time: item.clientProxyTime, clientOrder: item.clientOrder },
          { time: item.clientDataTime, clientDataOrder: item.clientDataOrder }
        ]
        temp.sort(this.compare)
        for (let i = 0; i < 4; i++) {
          for (let data in temp[i]) {
            if (data.endsWith("Order")) {
              if (item[data] !== undefined) {
                item[data] = i + 1
              }
            }
          }
        }
      })
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.proxyServer && item.proxyData && item.clientProxy && item.clientData) {
        if (item.proxyServerTime && item.clientProxyTime) {
          this.timeDifference(item)
        }
        return 'table-success'
      }
      if (item.proxyServerTime && item.clientProxyTime) {
        this.timeDifference(item)
      }
    },
    transformDataFormat(ctx) {
      let dataItems = { items: [] }
      for (let i = 0; i < ctx.length; i++) {
        let campaign = {
          campaignserver: ctx[i]["gCampaign"],
          campaigndata: ['uno', 'dos']
        }
        if (campaign.campaignserver !== undefined) {
          campaign.campaigndata = campaign.campaignserver.split('#')
        }
        dataItems.items.push({
          clickNum: ctx[i]["cli.gClid"],
          proxyServer: ctx[i]["sta.ser.pro"],
          proxyData: ctx[i]["sta.ser.dat"],
          clientProxy: ctx[i]["sta.cli.pro"],
          clientData: ctx[i]["sta.cli.dat"],
          proxyServerTime: ctx[i]["sta.ser.pro.tim"],
          proxyDataTime: ctx[i]["sta.ser.dat.tim"],
          clientProxyTime: ctx[i]["sta.cli.pro.tim"],
          clientDataTime: ctx[i]["sta.cli.dat.tim"],
          accountName: ctx[i]["cli.accName"],
          campaignName: ctx[i]["cli.camName"],
          gCampaign: campaign.campaigndata[1],
          clientDataOrder: 0,
          clientOrder: 0,
          proxyOrder: 0,
          proxyDataOrder: 0,
          firstDate: 0,
          timediffProxy: 0,
          timediffClient: 0,
          _rowVariant: 'null'
        });
      }
      return dataItems.items
    },
    chargeItems() {
      this.$vs.loading()
      Amplify.Auth.currentSession().then(apiSession => {
        this.$axios
          .get(`/admin/clickstatus`, {
            headers: {
              Authorization: "Bearer " + apiSession.idToken.jwtToken
            }
          })
          .then(response => {
            const results = response.data
            const dataitems = this.transformDataFormat(results)
            this.items = this.items.concat(dataitems)
            this.firstElement = results.lastIndex
            this.changePos()
            this.getFirstDate()
            this.calculatePercent()
            setTimeout(() => {
              this.mediaTime()
            }, 500)
            this.$vs.loading.close()
          })
        return null
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onRowSelected(items) {
      if (items.length > 0) {
        this.rowSel = true
        this.ipSelectec = items[0].proxyServer
      } else {
        this.rowSel = false
        this.ipSelectec = ''
      }
    },
  },
  mounted() {
    this.chargeItems()
    this.hideheader()
  },
  destroyed() {
    this.showheader()
  }
}
</script>
<style>
table.ana-table .flip-list-move {
  transition: transform 1s;
}
.ana-table th {
  font-size: 1.3rem;
  text-align: center;
}
.ana-table td {
  vertical-align: middle;
  text-align: center;
}
.table-ps-column {
  width: 12%;
}
.table-pd-column {
  width: 12%;
}
.table-cp-column {
  width: 12%;
}
.table-cd-column {
  width: 12%;
}
.table-click-column {
  width: 52%;
}
.table-ul-list {
  margin-bottom: 0;
  display: flex;
  justify-content: center;
}
.showcontainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.showcontainer > li {
  margin: 2px auto;
}
.innercontainer {
  display: flex;
  flex-direction: column;
}
.vanish {
  display: none !important  ;
}
.no-top {
  margin-top: 0 !important;
}
.notdpadding > table > tbody > tr > td {
  padding: 0 !important;
}
.thsize {
  font-size: small !important;
}
.tb-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.inline-list {
  display: inline-flex;
  justify-content: center;
}
.text-small {
  font-size: smaller;
}
</style>